import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Headline, StyledHeadlineWrapper } from "../components/headline"

export default () => (
  <Layout>
    <SEO title="404: Not found"/>
    <div className={"container"}>
      <div className={"row justify-content-center"}>
        <div className={"col-12"}>
          <div className={"row"}>
            <StyledHeadlineWrapper>
              <Headline
                headlineH1="NOT FOUND"
                thinSubline="You just hit a route that doesn&#39;t exist... the sadness."
              />
            </StyledHeadlineWrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
